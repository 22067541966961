import React, { useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';
import queryString from 'query-string';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CGeneralMedia,
  CSectTitle,
  CSectTitle03,
  CDefinition,
  LOgata,
  CSimpleAccordionList,
  CustomLink,
} from '../../../../components/_index';
import shopGet from '../../../../utils/shop-get';
import menuChoice from '../../../../utils/menu-choice';
import menuGet from '../../../../utils/menu-get';

// markup
const SubPage = ({ data, location }: any) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const { contentId, draftKey } = queryString.parse(location.search);
  const [shopdata, setShopData] = useState<any | null>(shopGet('keikaen'));
  if (contentId) {
    useEffect(() => {
      fetch(
        `https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/shop_srph/${contentId}?draftKey=${draftKey}`,
        {
          headers: {
            'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || '',
          },
        }
      )
        .then((res) => res.json())
        .then((res) => setShopData(res));
    }, []);
  }

  return (
    <Layout isKeikaen={true}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="exLarge"
        data={{
          title: {
            en: ' BUSINESS',
            ja: '宴会・会議',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/keikaen/business/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/keikaen/business/kv__sp.png',
              },
            },
          ],
        }}
        exClass="c_jumbotron_keikaen"
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン',
                path: '/restaurants/',
              },
              {
                label: '中国料理「桂花苑」',
                path: '/restaurants/keikaen/',
              },
            ],
            current: {
              label: frontmatter?.title.includes('-')
                ? frontmatter?.title.split('-')[1]
                : frontmatter?.title,
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <CSectTitle03
            type="en"
            title={{
              main: (
                <>
                  MEETING <br className="u_sp" />& PARTY
                </>
              ),
              sub: '14名〜40名',
            }}
          />
          <CGeneralMedia
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/keikaen/business/img_business.png',
                  alt: '',
                },
                title: (
                  <>
                    セミナーや研修から
                    <br />
                    懇親会までワンストップの
                    <br />
                    ビジネスプラン
                  </>
                ),
                text: (
                  <>
                    <p>
                      心を潤す緑豊かな美しい景色と心を満たす美味しい中国料理。リラックス＆リフレッシュできるリゾートホテルの最上階で有意義なビジネスタイムを。
                    </p>
                  </>
                ),
                btn: {
                  label: 'MAILでのお問合せ',
                  link: {
                    href: 'https://contact.royalparkhotels.co.jp/srph/contact?_ifbs-srph_contact_form=s1_Step1',
                    blank: true,
                  },
                  color: 'borderBlack',
                },
              },
            ]}
          />
          <CSimpleAccordionList
            data={[
              {
                title: <>プラン内容</>,
                content: (
                  <>
                    <p className="u_mb30">
                      料金
                      <br />
                      20名様でのお見積り例
                      <br />
                      282,000円～
                      <ul className="c_noteList">
                        <li>1名追加：9,100円～</li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      内容
                      <br />
                      <ul className="c_circleList">
                        <li>MEETING室料（2時間）</li>
                        <li>料理（チャイニーズブッフェ）</li>
                        <li>飲物</li>
                        <li>会食室料</li>
                        <li>テーブルクロス／ナフキン</li>
                      </ul>
                      <ul className="c_noteList">
                        <li>
                          料理、飲物の詳細につきましてはお問い合わせください。
                        </li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      時間
                      <br />
                      例）
                      <ul className="c_circleList">
                        <li>MEETING:15:00～17:30までの2時間</li>
                        <li>PARTY:17:30～21:00までの2時間</li>
                      </ul>
                      <ul className="c_noteList">
                        <li>時間はご相談ください。</li>
                        <li>PARTYのみでも承ります。</li>
                      </ul>
                      <ul className="c_circleList">
                        <li>昼の部 11:30～15:00までの2時間</li>
                        <li>夜の部 17:30～21:00までの2時間</li>
                      </ul>
                    </p>

                    <p className="c_sectLead u_mbMedium">オプション</p>
                    <ul className="c_circleList">
                      <li>スクリーン：11,000円</li>
                      <li>プロジェクター：33,000円</li>
                      <li>
                        コーヒー＆ミネラルウォーターステーション（お一人様）：1,330円
                      </li>
                    </ul>
                    <ul className="c_noteList">
                      <li>
                        上記内容以外でのご利用をご希望の際はご相談ください。
                      </li>
                    </ul>
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect u_bgWhite">
        <LWrap>
          <CSectTitle03
            type="en"
            title={{ main: 'GUIDE', sub: '営業時間｜ご利用案内' }}
          />
          <CDefinition
            exClass="u_mb30"
            data={shopdata.information.map((item: any) => {
              return {
                title:
                  item.keyname &&
                  item.keyname.split('\n').map((t: string) => (
                    <>
                      {t}
                      <br />
                    </>
                  )),
                text: (
                  <div
                    className="wysiwyg"
                    dangerouslySetInnerHTML={{
                      __html: `${item.value}`,
                    }}
                  />
                ),
              };
            })}
          />
          <ul className="c_noteList">
            <li>掲載の内容は予告なく変更する場合があります。</li>
            <li>画像はイメージです。</li>
            <li>表示価格は消費税、サービス料込みの価格です。</li>
            <li>
              メニュー内容は仕入れの状況によって変更になる場合がございます。
            </li>
          </ul>
        </LWrap>
      </section>
      {/* <LOgata /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
